<template>
  <iframe id="iframe" frameborder="0" @load="sendMessage"
    :src="url" scrolling="yes" ref="iframe"></iframe>
</template>
<script>
import { clearLoginInfo } from '@/utils'
export default {
  data () {
    return {
      url: '',
      appId: '',
      iframeWindow: null
    }
  },
  created () {
    this.url = process.env.VUE_APP_SPLIT_URL
  },
  mounted () {
    this.iframeWindow = this.$refs.iframe.contentWindow
    if (this.$route.query.code) {
      this.$code = this.$route.query.code
    }
    if (this.$route.query.name) {
      this.$name = this.$route.query.name
    }
    // appToken验证失败时跳到登录页
    window.addEventListener('message', (event) => {
      if (event.data == 401) {
        clearLoginInfo()
        this.$message.error('token验证失败，请重新登录')
        this.$router.push({ name: 'login' })
      }
    })
  },
  methods: {
    sendMessage () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/getDomain'),
        method: 'get',
        params: this.$http.adornParams({
          'orgId': this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.iframeWindow.postMessage({
            orgId: this.$orgId,
            code: this.$code,
            isJM: true,
            title: this.$name,
            domain: data.domain
          }, '*')
        } else {
          this.$message.error(data.msg)
        }
      })
    }
  }
}
</script>
<style scoped>
iframe {
  display: block; /* iframes are inline by default */
  border: none; /* Reset default border */
  height: 100vh !important; /* Viewport-relative units */
  width: 100vw !important;
}
</style>
